<!-- //
标签下拉选择器
@auth xuyd
@date 2022-09-10
//-->
<template>
  <div class="select-container">
    <a-select
        mode="tags"
        placeholder="请选择或者添加标签"
        v-model="selectedItems"
        style="width: 100%"
        size="large"
        :labelInValue="true"
        :getPopupContainer="getAppMainDom"
        @dropdownVisibleChange="handleOpenDropdown"
        @change="handleSelectChange"
    >

      <!--      :token-separators="[' ',',']"-->
      <div slot="dropdownRender">
        <a-tabs :activeKey="activeKey" type="card" @mousedown="e => e.preventDefault()" @change="handleTabChange">
          <a-tab-pane v-for="(item, index) in tabGroupList" :key="index" :tab="item.label" style="text-align: center">
            <a-spin v-if="loading"/>
          </a-tab-pane>
        </a-tabs>
        <div class="tab-pane" @mousedown="e => e.preventDefault()">
          <a-tag v-for="childItem in tabList"
                 :key="childItem.key"
                 class="tag"
                 :class="[selectedItems.some(dbItem => dbItem.key == childItem.key) ? 'select' : '']"
                 @click="handleChecked(childItem)">
            <span>{{childItem.label}}</span>
          </a-tag>

          <!--          <a-input ref="refInput" class="input"-->
          <!--                   v-model="newTabVal"-->
          <!--                   v-show="!loading && newTabShow"-->
          <!--                   @blur="handleNewTabBlur"-->
          <!--                   @mousedown="e => e.preventDefault()"/>-->
          <a-tag @click="handleAddNewTab" class="tag" v-if="!loading">
            <span>+自定义</span>
          </a-tag>
        </div>
      </div>
    </a-select>

    <a-modal
        v-model="showModal"
        width="400px"
        title="添加标签"
        :bodyStyle="{'height': '220px'}"
        :footer="null"
        :keyboard="false"
        :maskClosable="false"
        :mask="true"
        :destroyOnClose="true">
      <TagAdd :parentTab="parentTab" @handleOk="handleModalAdd"/>
    </a-modal>
  </div>
</template>

<script>
  import * as TabApi from "@/api/content/tag.api";
  import TagAdd from "./tag-add";

  const _opts = {
    valueField: 'tabId',
    parentValueField: 'tabParentId',
    labelField: 'tabName',
  };
  export default {
    components: {
      TagAdd
    },
    data() {
      return {
        loading: false,
        activeKey: 0,
        parentTab: null, // 根据选择而修改
        selectedItems: this.convertToCompData(this.value), // 固定绑定{key, label}
        tabGroupList: [],// {tab item}=>{key, label, 0}
        tabList: [],// {tab item}=>{key, label, parentKey}
        showModal: false,

        openSelect: false, // 显示/关闭 下拉区域
        newTabFocus: false,// blur的来源是newTab（true） focus还是正常blur（false）（前者，openSelect是true）
        newTabShow: false,
        newTabVal: null,
      };
    },
    props: {
      options: {
        type: Object,
        default: function () {
          return _opts
        }
      },
      value: {
        type: Array,
        default: function () {
          return []
        }
      },
      limit: {
        type: Number,
        default: 6
      },
      // 可否交叉选择，默认false，即只能选择同一个组内tag
      cross: {
        type: Boolean,
        default: false
      },
      // 可否包含父级tag，默认true，即父级的tag也作为一项返回
      includeParent: {
        type: Boolean,
        default: true
      },
    },
    // model: {
    //   prop: 'value',
    //   event: 'selectChange',
    // },
    watch: {
      value(val) {
        this.selectedItems = this.convertToCompData(val)
        // console.log('watch#value=====>', this.selectedItems)
      },
    },
    mounted() {
      this.init()
    },

    methods: {
      // 转换db数据为组件数据结构
      convertToCompData(val) {
        val = val || []
        let selItems = val.map(item => {
          // 首次进来转换，以后再次转换parentKey会丢失，所以用parentTab存储
          return {key: item[this.options.valueField], label: item[this.options.labelField], parentKey: item[this.options.parentValueField]}
        })
        return selItems
      },

      // 转换组件数据为db数据结构
      convertToDbData(selItems) {
        selItems = selItems || []
        let retData = []
        selItems.forEach(item => {
          let d = {}
          d[this.options.valueField] = item.key
          d[this.options.labelField] = item.label
          retData.push(d)
        })
        return retData
      },

      /**
       * 初始化调用tab根节点，然后获取默认第一个节点
       */
      async init() {
        let params = {level: 1}
        params[this.options.parentValueField] = 0
        TabApi.search(params).then(data => {
          this.tabGroupList = data.map(item => {
            return {
              key: item[this.options.valueField],
              label: item[this.options.labelField],
              parentKey: item[this.options.parentValueField]
            }
          })
        })
      },

      /**
       * 切换tab事件
       * @param index pane的索引值
       */
      async handleTabChange(index) {
        this.activeKey = index
        this.tabList = []
        this.newTabVal = null
        // 如果不可交叉选择，则清除另外tab的选择
        if (!this.cross) {
          this.selectedItems = []
        }
        this.parentTab = this.tabGroupList[index]
        // 如果包含父级
        if (this.includeParent) {
          this.selectedItems.push(this.parentTab);
        }
        await this.doSearchSubTab(this.parentTab)
        this.$emit('change', this.convertToDbData(this.selectedItems))
      },

      /***
       * 查找子节点并挂接
       * @param currentParent 当前父节点
       */
      async doSearchSubTab(currentParent) {
        this.loading = true
        let params = {level: 2}
        params[this.options.parentValueField] = currentParent.key
        await TabApi.search(params).then(children => {
          this.loading = false
          this.tabList = children.map(item => {
            return {
              key: item[this.options.valueField],
              label: item[this.options.labelField],
              parentKey: item[this.options.parentValueField]
            }
          })
        }).catch(err => {
          this.loading = false
        })
      },

      handleOpenDropdown(open) {
        console.log("handleOpenDropdown", open)
        if (open) {
          if (!this.parentTab) {// 首次打开
            this.parentTab = this.getParentTab()

            // 如果包含父级
            if (this.includeParent) {
              // 没有值，初始化一个
              if(this.selectedItems.length <= 0) {
                this.selectedItems.push(this.parentTab);
                this.$emit('change', this.convertToDbData(this.selectedItems))
              }
            }
          }
          let index = this.tabGroupList.findIndex(groupItem => groupItem.key == this.parentTab.key)
          this.activeKey = index === -1 ? 0 : index;
          // let crr = this.tabGroupList[this.activeKey]
          // this.parentTab = {...crr}
          this.doSearchSubTab(this.parentTab)
        }
      },

      /**
       * 根据选择的，查找所属父级
       * 只有首次才有效，因为当再次open，selectedItems的parentKey已经丢失
       */
      getParentTab() {
        // 初始化显示父级tab
        let itemList = this.selectedItems || []
        let pkey = null
        let selectedItem = itemList.find(item => item.parentKey === 0)
        if (selectedItem) {
          pkey = selectedItem.key
        } else {
          selectedItem = itemList.find(item => item.parentKey > 0)
          if(selectedItem && selectedItem.length > 0) {
            pkey = selectedItem.parentKey
          }
        }
        if (pkey) {
          return this.tabGroupList.find(groupItem => groupItem.key == pkey)
        } else {
          if (this.tabGroupList.length > 0) return this.tabGroupList[0]
        }
        return null
      },

      /**
       * 当删除时候
       */
      handleSelectChange(selectedItems) {
        console.log("handleSelectChange", this.selectedItems)
        this.$emit('change', this.convertToDbData(this.selectedItems))
      },

      /**
       * 选择
       */
      handleChecked(subItem) {
        console.log("handleChecked", subItem)
        // 校验是否存在重复
        let exist = this.selectedItems.some(item => item.key == subItem.key)
        if (exist) return;

        // 校验是否达到上线
        if (this.limit > 0) {
          if (this.selectedItems.length >= this.limit) {
            this.$modal.msgSuccess(`最多可以选择${this.limit}个`)
            return;
          }
        }

        this.selectedItems.push(subItem);
        console.log("=====>tagSelector", this.selectedItems)
        this.$emit('change', this.convertToDbData(this.selectedItems))
      },

      /**
       * 自定义按钮点击
       */
      handleAddNewTab() {
        this.showModal = true
      },

      /**
       * dialog的新增标签按钮
       */
      handleModalAdd({parentTab, label}) {
        // 调用接口保存到db
        let tagItem = {tabName: label}
        tagItem[this.options.parentValueField] = parentTab.key

        TabApi.add(tagItem).then(data => {
          this.$modal.msg(`在${parentTab.label}下新增标签${label}成功`)
          this.showModal = false
          this.doSearchSubTab(parentTab)
        }).catch(err => {
          // 异常 TODO 测试用
          this.doSearchSubTab(parentTab)
        })
      },

      getAppMainDom() {
        return document.getElementById('refAppMain')
      }
    },
  }
</script>

<style lang="scss" scoped>
  .tab-pane {
    padding: 0 20px 20px 20px;
    max-height: 250px;
    overflow-y: auto;

    .tag {
      font-size: 14px;
      text-align: center;
      min-width: 93px;
      height: 40px;
      line-height: 40px;
      color: #000000;
      background-color: #f7f7f7;
      cursor: pointer;
      margin: 7.5px 9px;

      .label {
        height: 50%;
        line-height: 40px;
      }

    }

    .select {
      color: #FEFEFE;
      background-color: #1890ff;
    }

    .input {
      width: 100px;
      height: 40px;
      margin-right: 10px;
    }
  }
</style>

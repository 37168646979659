<script>

  import {setBusinessUser, setToken} from "@/utils/auth";

  export default {
    created() {
      const {params, query} = this.$route
      const {path} = params

      console.log('------redirect------', this.$route)
      let bizUser = {businessUserId: query.businessUserId, businessType: query.businessType};
      setBusinessUser(bizUser);
      setToken(query.ssoToken);
      let queryCpy = {...query};
      delete queryCpy['ssoToken']
      delete queryCpy['businessUserId']
      delete queryCpy['businessType']
      this.$router.replace({path: '/' + path, query: queryCpy}).catch(err => {
      })
    },
    render: function (h) {
      return h() // avoid warning message
    }
  }
</script>
